import * as React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { useLocation } from "@reach/router"
import {useI18next, useTranslation} from "gatsby-plugin-react-i18next"

const AnchorLinkCustom = ({children,href,anchor,language,className}) => {
  const {t} = useTranslation()
  const location = useLocation()
  let to = href ? href : location.pathname+anchor

  if(!language){
    if( (! anchor && t("lang") ==='en')){
      to = '/en'+to
    }
  }else{
    if(language!=='ja'){
      to = '/en'+to
    }
  }

  
  return (
    <AnchorLink to={to} className={className} stripHash>{children}</AnchorLink>
  )
}

export default AnchorLinkCustom