import * as React from "react"
import {Link, useI18next, useTranslation} from "gatsby-plugin-react-i18next"
import { slide as Menu } from "react-burger-menu";


import iconBlank01 from "/src/images/icon-blank-01.png"
import headerimage from "/src/images/header-image.png"

export default props => {
  
  const {t} = useTranslation();

  return (
      <>
        <ul className="headerUtility">
          <li><Link to="/access/" className="access">{t("アクセス")}</Link></li>
          <li><Link to="/contact/" className="contact">{t("お問い合わせ")}</Link></li>
          <li><Link to="/topics/" className="topics">{t("お知らせ")}</Link></li>
        </ul>

        <div className="siteName">
          <Link to="/">{t("理化学研究所 筑波キャンパス")}</Link>
        </div>

        <div>
          <ul className="globalMenu">
            <li><Link to="/campus/">{t("筑波キャンパスについて")}</Link></li>
            <li><Link to="/centers/">{t("センター紹介")}</Link></li>
            <li><Link to="/experience/">{t("研究成果の普及")}</Link></li>
            <li><Link to="/partnerships/">{t("地域での活動")}</Link></li>
          </ul>
        </div>

        <img className="headerImage" src={ headerimage } alt="" />

        <ul className="headerLinks">
          <li><Link to="/sitemap/">{t("サイトマップ")}</Link></li>
          <li><Link to="/accessibility/">{t("ウェブアクセシビリティ")}</Link></li>
          <li>
            <a href={"https://"+t("www.riken.jp/")} rel="noreferrer noopener" target="_blank">
              {t("理化学研究所")}
              <img className="iconBlank" src={ iconBlank01 } alt={t("新規タブで開きます")} />
            </a>
          </li>
          <li>
            <a href={"https://"+t("www.riken.jp/careers/")} rel="noreferrer noopener" target="_blank">
              {t("採用情報（理化学研究所）")}
              <img className="iconBlank" src={ iconBlank01 } alt={t("新規タブで開きます")} />
            </a>
            
          </li>
          <li>
            <a href="https://choutatsu.riken.jp/r-world/info/procurement/" rel="noreferrer noopener" target="_blank">
              {t("調達情報（理化学研究所）")}
              <img className="iconBlank" src={ iconBlank01 } alt={t("新規タブで開きます")} />
            </a>
          </li>
        </ul>
      </>
  )
}
