import * as React from "react"
import { Link } from "gatsby"

import {useI18next, useTranslation} from "gatsby-plugin-react-i18next"
import { useLocation } from "@reach/router"

function LangSwitch(language) {
  const location = useLocation();
  if(language.lang==='en'){
    return (
      <Link className="langswitch" to={location.pathname.replace('/en','')===''?'/':location.pathname.replace('/en','')}>
        <span lang="ja">日本語</span>
      </Link>
    )
  }else{
    return (
      <Link className="langswitch" to={'/en'+location.pathname}>
        <span lang="en">English</span>
      </Link>
    )
  }
}

export default LangSwitch
