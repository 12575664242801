import * as React from "react"

import {Link, useI18next, useTranslation} from "gatsby-plugin-react-i18next"
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { useLocation } from "@reach/router"

import logoriken from "/src/images/logo-riken.png"

const Footer = () => {
  const {t} = useTranslation();
  const location = useLocation()

  return (
    <footer>
      <div className="search-box">
        <form action={t("/search/")} method="get">
          <span className="search-input">
            <input type="search" id="searchkeywords" name="q" title={t("サイト内検索")} placeholder={t("Googleカスタム検索")}/>
          </span>
          <span className="search-btn">
            <button className="searchbtn" type="submit">
              <span className=".screen-reader-text">{t("検索")}</span>
            </button>
          </span>
          
        </form>
      </div>
      <div className="organization-box">
        <div className="organization-box-left">
          <img className="logo" src={ logoriken } alt={t("理化学研究所ロゴ")} />
        </div>
        <div className="organization-box-right">
          <div className="organization-name">
            {t("理化学研究所 筑波事業所")}
          </div>
          <div className="organization-address">
            {t("〒305-0074 茨城県つくば市高野台3-1-1")}
          </div>
        </div>
      </div>
      <p className="pagetop">
        <AnchorLink 
          to={location.pathname+"#top"}
          title={t("このページのトップへ")}>
          <span className="screen-reader-text">{t("このページのトップへ")}</span>
        </AnchorLink>
      </p>
    </footer>
  )
}

export default Footer
