import React from "react";

import AnchorLink  from "/src/components/link-anchor"

export default function skiplink(prop) {
  
  let click = () => {
    const focus = () => document.getElementById('main').focus()
  }

  return (
    <p className="skip-nav">
      <a href="#main" className="skip-link" onClick={click}>{prop.lang==='ja'?'このページの本文へ':'Skip to main content'}</a>
    </p>
  )
}
