import React from "react"
import { Link } from "gatsby"
import LangSwitch from "./langswitch"
import Bredcrumb from "./bredcrumb"

const ContentHeader = ({bredcrumb,lang}) => {
  
  return (
    <div className="content-header">
      <div className="left">
        <Bredcrumb bredcrumb={bredcrumb}/>
      </div>
      <div className="right">
        <LangSwitch lang={lang}/>
      </div>
    </div>
  )
  
}

export default ContentHeader

