import * as React from "react"

const ContentLayout = ({children,lang,onlyjatext}) => {
  let ja = ''
  if(onlyjatext && lang === 'en'){
    ja = <p className="annotation">Sorry. This article is written in Japanese text only.</p> 
  }
  const lng = onlyjatext ? 'ja' : lang

  return (
    <article id="main" className="article">
      <div className="content-box-wrap">
        <div className="content-box">
          {ja}
          <div lang={lng}>
            {children}
          </div>
        </div>
      </div>
    </article>
  )
}

export default ContentLayout
