import * as React from "react"
import PropTypes from "prop-types"

import Header from "./header"
import ContentHeader from "./content-header"
import Footer from "./footer"
import "./layout.css"


const Layout = ({children,bredcrumb,lang}) => {
  

  return (
    <div className="wrap" id="top">
      <Header />
      <div className="main-box">
        <ContentHeader bredcrumb={bredcrumb} lang={lang}/>
        <main>
          {children}
        </main>
        <Footer />
      </div>
      
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
