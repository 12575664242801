import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function Seo({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  let defaultTitle = site.siteMetadata?.title
  if(lang=='en'){
    defaultTitle = ' RIKEN Tsukuba Campus'
  }

  const ttemp = title === 'home' ? defaultTitle : `%s | ${defaultTitle}`

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      //titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      titleTemplate={  title === 'home' ? defaultTitle :  (defaultTitle ? `%s | ${defaultTitle}` : null)}

      link={[{ rel: 'canonical', href: 'canonical' }]}
      link={[{ rel: 'stylesheet', href: 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.9.0/css/all.min.css' }]}
      
      meta={[
        /*{
          name: `description`,
          content: metaDescription,
        },
        */
      ].concat(meta)}
    />
  )
}

Seo.defaultProps = {
  lang: `ja`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo
