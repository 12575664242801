import * as React from "react"
import {Link, useI18next, useTranslation} from "gatsby-plugin-react-i18next"

import { slide as Menu } from "react-burger-menu"
import MenuContent from "./menu-content"

import SkipLink  from "/src/components/skiplink"

const Header = () => {
  
  const {t} = useTranslation();
  
  return (
    <header>
      <SkipLink lang={t('lang')}/>
      <Menu width={1200} id="menu" >
        <MenuContent />
      </Menu>
      <div className="mobile">
        <div className="siteName">
          <Link to="/">{t("理化学研究所 筑波キャンパス")}</Link>
        </div>
      </div>
      <div className="pc">
        <MenuContent />
      </div>
    </header>
  )


  
}

export default Header

