import * as React from "react"
import {Link, useI18next, useTranslation} from "gatsby-plugin-react-i18next"

function Bredcrumb({bredcrumb}) {

  const {t} = useTranslation();

  var list = [];
  for(var i in bredcrumb){
    
    list.push(
      <li>
        <Link to={bredcrumb[i].pathname}>
          {t(bredcrumb[i].crumbLabel)}
        </Link>
        
      </li>
    );
  }
  if(bredcrumb!=''){
    return (
      <ul className="bredcrumb">
          {list}
      </ul>
    )
    
  }else{
    return (
      <></>
    )
  }
  
  
}

export default Bredcrumb
